import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes, Outlet } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import SignIn from "./pages/signIn/";
import SignUp from "./pages/signUp/";
import { ThemeProvider } from "./libs/darkMode/ThemeContext";
import Background from "./libs/darkMode/Background";
import NotFound from "./pages/notFound/";
import ProtectedRoute from "./libs/protected";
import IsLoggedIn from "./libs/loggedIn";
import Nav from "./libs/nav";
import Console from "./pages/console";
import Dashboard from "./pages/orders/dashboard";
import Order from "./pages/orders/order";
import OrderConfirmed from "./pages/orderConfirmed/";
import SampleDataSet from "./pages/sampleDataset/sampleDataset";
import Admin from "./pages/admin/";
import Charts from "./pages/admin/charts";
import AdminNav from "./components/adminNav";
import Logs from "./pages/admin/logs";
import AdminProtected from "./libs/adminProtected";
import Users from "./pages/admin/users";
import UserJoinedOrg from "./pages/userJoinedOrg/";
import User from "./pages/admin/users/user/index";
import Log from "./pages/admin/logs/log/index";
import AccountVerified from "./pages/accountVerified";
import AdaniTransmissions from "./pages/adaniTransmissions/";
import TataSteel from "./pages/tataSteel";

function LayoutsWithNavbar() {
  return (
    <div className="w-full h-full grid grid-rows-10">
      {/* Your navbar component */}
      <div className="row-span-1">
        <Nav />
      </div>

      {/* This Outlet is the place in which react-router will render your components that you need with the navbar */}
      <div className="row-span-9">
        <Outlet />
      </div>

      {/* You can add a footer to get fancy in here :) */}
    </div>
  );
}

function LayoutWithAdminNavbar() {
  return (
    <div className="w-full h-full flex">
      {/* Your navbar component */}
      <div className="w-0.5/10 border-white bg-nav">
        <AdminNav />
      </div>

      {/* This Outlet is the place in which react-router will render your components that you need with the navbar */}
      <div className="w-95/100">
        <Outlet />
      </div>

      {/* You can add a footer to get fancy in here :) */}
    </div>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <ThemeProvider>
        <Background>
          {/* <Toggle />
          <img
            src="./logo/main-hero.png"
            alt="logo"
            className="w-32 h-16 absolute top-10 left-10"
          /> */}

          <BrowserRouter>
            <Routes>
              <Route path="/" element={<LayoutsWithNavbar />}>
                <Route
                  path="/"
                  element={
                    <ProtectedRoute>
                      <Console />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="orders"
                  element={
                    <ProtectedRoute>
                      <Dashboard />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="orders/:id"
                  element={
                    <ProtectedRoute>
                      <Order />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route
                path="sign-in"
                element={
                  <IsLoggedIn>
                    <SignIn />
                  </IsLoggedIn>
                }
              />
              <Route
                path="/adani-transmission"
                element={
                  <ProtectedRoute>
                    <AdaniTransmissions />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/tata-steel"
                element={
                  // <ProtectedRoute>
                  <TataSteel />
                  // </ProtectedRoute>
                }
              />

              <Route
                path="sign-up"
                element={
                  <IsLoggedIn>
                    <SignUp />
                  </IsLoggedIn>
                }
              />
              <Route path="/" element={<LayoutWithAdminNavbar />}>
                <Route
                  path="admin"
                  element={
                    <ProtectedRoute>
                      <AdminProtected>
                        <Admin />
                      </AdminProtected>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="admin/logs"
                  element={
                    <ProtectedRoute>
                      <AdminProtected>
                        <Logs />
                      </AdminProtected>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="admin/logs/:log"
                  element={
                    <ProtectedRoute>
                      <AdminProtected>
                        <Log />
                      </AdminProtected>
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="admin/users"
                  element={
                    <ProtectedRoute>
                      <AdminProtected>
                        <Users />
                      </AdminProtected>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="admin/users/:id"
                  element={
                    <ProtectedRoute>
                      <AdminProtected>
                        <User />
                      </AdminProtected>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="admin/charts"
                  element={
                    <ProtectedRoute>
                      <AdminProtected>
                        <Charts />
                      </AdminProtected>
                    </ProtectedRoute>
                  }
                />
              </Route>

              <Route
                path="order-confirmed/:id"
                element={
                  <ProtectedRoute>
                    <OrderConfirmed />
                  </ProtectedRoute>
                }
              />
              <Route
                path="join-organization/:id/:organisationName"
                element={
                  <ProtectedRoute>
                    <UserJoinedOrg />
                  </ProtectedRoute>
                }
              />
              <Route
                path="account-verified/:id"
                element={<AccountVerified />}
              />

              <Route path="*" element={<NotFound />} />
            </Routes>
          </BrowserRouter>
        </Background>
      </ThemeProvider>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
