import Lottie from "react-lottie";
import { useState } from "react";
import { FaSpinner } from "react-icons/fa";
import satelliteLottie from "../../lotties/satellite/satellite.json";
import signup from "../../lotties/signup/signup.json";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import { useAtom } from "jotai";
import { userAtom } from "../../atoms/index";
import { creditsAtom } from "../../atoms/index";
import Tracker from "@openreplay/tracker";

const lottieSatellite = {
  loop: true,
  autoplay: true,
  animationData: satelliteLottie,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const SignupSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().required("Required"),
});

const config = {
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
};

const onSubmit = async (
  values,
  isError,
  navigate,
  setIsLoading,
  setUser,
  from,
  setCredits
) => {
  try {
    console.log("sign in submit");
    const req = await axios.post(
      `${process.env.REACT_APP_AUTH_SERVER}/auth/v1/sign-in`,
      {
        email: values.email,
        password: values.password,
      },
      config
    );
    console.log(req, "req from signin");
    if (req.status === 200) {
      setIsLoading(false);
      console.log(req.data.data);
      console.log(from, "location");
      setUser(req.data.data);
      setCredits(req.data.data.credits);
      console.log(req.data.data.credits, "credits");
      navigate(from, { replace: true });
      const tracker = new Tracker({
        projectKey: "h2i72okKhEoMPnWzG4Eb",
      });
      tracker.start();
      tracker.setUserID(req.data.data?.email);
      //navigate to requested url
    }
  } catch (error) {
    console.log("error in signin", error);
    console.log(error.response);
    setIsLoading(false);
    isError(true);
    setUser(null);
    toast.error(error.response.data.message);
  }
};

export default function Login() {
  console.log("sign in up");
  let navigate = useNavigate();
  const location = useLocation();
  const [error, isError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useAtom(userAtom);
  const [credits, setCredits] = useAtom(creditsAtom);
  console.log(location, "location signin");
  let from = location.state?.from?.pathname || "/";
  return (
    <main className="w-full h-full grid grid-cols-5 items-center">
      <div className="col-span-3 flex justify-center items-center pr-14 pt-10">
        <div className="w-4/10 h-1/2 flex items-center justify-center">
          <img src="./logo/main-hero.png" alt="logo"></img>
        </div>
        {/* <Lottie options={lottieSatellite} height={400} width={400} /> */}
      </div>
      <div className="col-span-2 grid grid-rows-4 justify-items-start items-center h-2/3 ">
        <div className="flex flex-col gap-3 row-span-1">
          <div>
            <h3 className="text-white text-4xl">Sign in</h3>
          </div>
          <div className="text-white">
            Want to get an
            <span
              className="ml-2 text-my_purple cursor-pointer"
              onClick={() => navigate("/sign-up")}
            >
              Early Access?
            </span>
          </div>
        </div>
        <div className="flex flex-col row-span-3 gap-7 w-full">
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={SignupSchema}
            onSubmit={(values) => {
              // same shape as initial values
              setIsLoading(true);
              console.log(values);
              onSubmit(
                values,
                isError,
                navigate,
                setIsLoading,

                setUser,
                from,
                setCredits
              );
            }}
          >
            {({ errors, touched }) => (
              <Form>
                <div className="flex flex-col gap-5">
                  <div className="w-full md:w-2/3 mb-6 md:mb-0">
                    <label
                      className="block uppercase tracking-wide text-white text-base font-bold mb-2 "
                      htmlFor="email"
                    >
                      Email
                    </label>

                    <Field
                      name="email"
                      className={`appearance-none block w-custom bg-input_bg text-white rounded py-3 px-4 mb-3 leading-tight focus:bg-background ${
                        (errors.email && touched.email) || error
                          ? "border border-red-500 focus:outline-none"
                          : "focus:outline-blue-500"
                      }`}
                      id="email"
                      type="email"
                      autoComplete="off"
                    />
                    <div className="text-red-500 text-xs italic">
                      {errors.email && touched.email ? (
                        <div>{errors.email}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="w-full md:w-2/3 mb-6 md:mb-0">
                    <label
                      className="block uppercase tracking-wide text-white  text-base font-bold mb-2"
                      htmlFor="password"
                    >
                      Password
                    </label>
                    <Field
                      name="password"
                      type="password"
                      className={`appearance-none block w-custom  bg-input_bg text-white rounded py-3 px-4 mb-3 leading-tight  focus:bg-background ${
                        (errors.password && touched.password) || error
                          ? "border border-red-500 focus:outline-none"
                          : "focus:outline-blue-500"
                      }`}
                      id="password"
                      autoComplete="off"
                    />
                    <div className="text-red-500 text-xs italic ">
                      {errors.password && touched.password ? (
                        <div>{errors.password}</div>
                      ) : null}
                    </div>
                  </div>
                  {/* <div className="w-custom">
                    <div className="flex justify-end">
                      <button className="outline-none border-none text-forgot_password hover:text-my_purple">
                        Forgot Password?
                      </button>
                    </div>
                  </div> */}
                  <div className={`${isLoading ? "flex" : ""}`}>
                    <button
                      className={`w-custom text-center text-black bg-white rounded py-3 ${
                        isLoading ? "justify-start" : ""
                      }`}
                      type="submit"
                    >
                      Sign in
                    </button>

                    <FaSpinner
                      className={`text-2xl animate-spin items-center relative right-10 top-3 text-black ${
                        isLoading ? "justify-end" : "hidden"
                      }`}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div></div>
      </div>
    </main>
  );
}
