import { useState } from "react";
// import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import * as dayjs from "dayjs";
import { useAtom } from "jotai";
import { orderDetailsAtom } from "../../atoms/index";
import { cloudCoverAtom } from "../../atoms/index";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import Slider from "@mui/material/Slider";
import { ErrorBoundary } from "react-error-boundary";
import { DateRangePicker, DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

dayjs().format();

const sidebarOnSubmit = (
  setCurrentSlide,
  setDetails,
  details,
  cloud,
  state
) => {
  console.log(state);
  console.log(dayjs(state[0].startDate).toISOString());
  console.log(dayjs(state[0].endDate).toISOString());
  setDetails({
    ...details,
    cloudCover: `[${cloud[0]} TO ${cloud[1]}]`,
    startDate: dayjs(state[0].startDate).toISOString(),
    endDate: dayjs(state[0].endDate).toISOString(),
  });
  setCurrentSlide("item-3");
};

const goBack = (setCurrentSlide) => {
  setCurrentSlide("item-1");
};

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div
      role="alert"
      className="w-full flex flex-col items-center justify-center gap-5 text-white"
    >
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <p className="text-center">
        It's an issue with firefox, try switching it to any other web browser
      </p>
      <button
        onClick={resetErrorBoundary}
        className="bg-red-400 px-5 py-2 rounded mt-5"
      >
        Try again
      </button>
    </div>
  );
}

const myErrorHandler = (error, info) => {
  console.error(error, info);
};

const Sidebar = ({ setCurrentSlide }) => {
  const [details, setDetails] = useAtom(orderDetailsAtom);
  const [cloud, setCloud] = useAtom(cloudCoverAtom);
  console.log(details.startDate);
  console.log(dayjs(details.startDate).toString());
  console.log(dayjs(details.endDate).toString());
  console.log(navigator.userAgentData.platform, "platform");
  const device = navigator.userAgentData.platform;
  const [state, setState] = useState([
    {
      startDate:
        details.startDate !== undefined
          ? new Date(details.startDate)
          : new Date(new Date() - 5 * 86400000),
      endDate: details.endDate ? new Date(details.endDate) : new Date(),
      key: "selection",
    },
  ]);

  console.log(state);
  const handleChange = (event, newValue) => {
    setCloud(newValue);
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
      onError={myErrorHandler}
    >
      <div className="flex flex-col w-full items-center justify-center gap-4">
        {device === "macOS" ? (
          <div>
            <div className="flex flex-col w-full items-center justify-center">
              <div className="flex justify-items-start w-full h-full relative">
                <div
                  className="absolute -top-1 left-1"
                  onClick={() => goBack(setCurrentSlide)}
                >
                  <MdOutlineKeyboardBackspace className="text-4xl cursor-pointer text-white" />
                </div>
                <div className="font-pipeline_name text-xl text-pipeline_name uppercase pb-3 text-center w-full">
                  <h2>Date Range</h2>
                </div>
              </div>
              <DateRange
                editableDateInputs={true}
                onChange={(item) => setState([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={state}
                // minDate={new Date(new Date() - 60 * 86400000)}
                maxDate={new Date(new Date() + 1 * 86400000)}
              />
            </div>
            <div className="flex flex-col w-9/10 items-center justify-center px-2">
              <h2 className="font-pipeline_name text-xl text-pipeline_name uppercase py-2 text-center w-full">
                Cloud cover percentage
              </h2>

              <Slider
                getAriaLabel={() => "Temperature range"}
                value={cloud}
                onChange={handleChange}
                valueLabelDisplay="auto"
              />
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-start max-h-102 overflow-y-auto">
            <div className="flex flex-col w-full items-center justify-center">
              <div className="flex justify-items-start w-full h-full relative">
                <div
                  className="absolute -top-1 left-1"
                  onClick={() => goBack(setCurrentSlide)}
                >
                  <MdOutlineKeyboardBackspace className="text-4xl cursor-pointer text-white" />
                </div>
                <div className="font-pipeline_name text-xl text-pipeline_name uppercase pb-3 text-center w-full">
                  <h2>Date Range</h2>
                </div>
              </div>
              <DateRange
                editableDateInputs={true}
                onChange={(item) => setState([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={state}
                // minDate={new Date(new Date() - 60 * 86400000)}
                maxDate={new Date(new Date() + 1 * 86400000)}
              />
            </div>
            <div className="flex flex-col w-9/10 items-center justify-center px-2">
              <div>
                <h2 className="font-pipeline_name text-xl text-pipeline_name uppercase py-3 text-center w-full">
                  Cloud cover percentage
                </h2>
              </div>
              <Slider
                getAriaLabel={() => "Temperature range"}
                value={cloud}
                onChange={handleChange}
                valueLabelDisplay="auto"
              />
            </div>
          </div>
        )}
        <button
          className="sm:py-2 sm:px-4 px-3 w-1/3 py-2 rounded-sm order-2 md:order-3 border ml-5 border-white  bg-white text-black  shadow-2xl dark:shadow-none transition duration-500 ease-in-out hover:-translate-y-1 hover:scale-105 active:translate-y-3"
          type="submit"
          onClick={() =>
            sidebarOnSubmit(setCurrentSlide, setDetails, details, cloud, state)
          }
        >
          Next
        </button>
      </div>
    </ErrorBoundary>
  );
};

export default Sidebar;
