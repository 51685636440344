import { motion } from "framer-motion";
import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import { useAtom } from "jotai";
import { userAtom } from "../../atoms/index";
import { FaSpinner } from "react-icons/fa";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const SignupSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().required("Required"),
  confirmPassword: Yup.string().required("Required"),
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  phoneNumber: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
  organisationName: Yup.string().required("Required"),
  industryType: Yup.string().required("Required"),
  country: Yup.string().required("Required"),
  jobDescription: Yup.string().required("Required"),
  termsAndCondition: Yup.bool().oneOf(
    [true],
    "You need to agree Terms & Conditions before proceeding"
  ),
});

const config = {
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
};

const countryList = [
  "--please select--",
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas (the)",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia (Plurinational State of)",
  "Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory (the)",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cayman Islands (the)",
  "Central African Republic (the)",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands (the)",
  "Colombia",
  "Comoros (the)",
  "Congo (the Democratic Republic of the)",
  "Congo (the)",
  "Cook Islands (the)",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czechia",
  "Côte d'Ivoire",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic (the)",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Falkland Islands (the) [Malvinas]",
  "Faroe Islands (the)",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories (the)",
  "Gabon",
  "Gambia (the)",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See (the)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran (Islamic Republic of)",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea (the Democratic People's Republic of)",
  "Korea (the Republic of)",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic (the)",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands (the)",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia (Federated States of)",
  "Moldova (the Republic of)",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands (the)",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger (the)",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands (the)",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine, State of",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines (the)",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Republic of North Macedonia",
  "Romania",
  "Russian Federation (the)",
  "Rwanda",
  "Réunion",
  "Saint Barthélemy",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin (French part)",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten (Dutch part)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan (the)",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands (the)",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates (the)",
  "United Kingdom of Great Britain and Northern Ireland (the)",
  "United States Minor Outlying Islands (the)",
  "United States of America (the)",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela (Bolivarian Republic of)",
  "Viet Nam",
  "Virgin Islands (British)",
  "Virgin Islands (U.S.)",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
  "Åland Islands",
];

const industryList = [
  "--please select--",
  "Aerospace",
  "Agriculture",
  "Airlines/Aviation",
  "Agriculture",
  "Automotive",
  "Banking",
  "Biotechnology",
  "Chemicals",
  "Communications",
  "Construction",
  "Consulting",
  "Defense and Intelligence",
  "Disaster Management",
  "Energy",
  "Education",
  "Electronics",
  "Fishery",
  "Food and Beverage",
  "Geospatial Services",
  "Government",
  "Healthcare",
  "Hospitality",
  "Insurance",
  "Machinery",
  "Manufacturing",
  "Media",
  "Maritime",
  "Metals",
  "Mining",
  "Not for profit",
  "Oil and Gas",
  "Pharmaceuticals",
  "Real Estate",
  "Retail",
  "Shipping",
  "Technology",
  "Telecommunications",
  "Transportation",
  "Utilities",
  "Value Added Services",
  "Other",
];

const onSubmit = async (
  values,
  isError,
  navigate,
  setIsLoading,
  setUser,
  from
) => {
  console.log(values);
  try {
    console.log("sign in submit");
    const user = {
      email: values.email,
      password: values.password,
      confirmPassword: values.confirmPassword,
      first_name: values.firstName,
      last_name: values.lastName,
      company_name: values.organisationName,
      country: values.country,
      contact_no: values.phone,
      job_description: values.jobDescription,
      organization_type: values.industryType,
    };
    const req = await axios.post(
      `${process.env.REACT_APP_AUTH_SERVER}/auth/v1/sign-up`,
      user,
      config
    );
    if (req.status === 200) {
      // setIsLoading(false);
      // console.log(from, "location");
      // setUser(req.data.data[0]);
      // navigate(from, { replace: true });
      console.log("signup success", req);
      toast.success(
        "Signup Successful, Please verify your email before signing-in!",
        {
          duration: 10000,
        }
      );
      navigate("/sign-in");
    }
  } catch (error) {
    console.log(error.response);
    setIsLoading(false);
    isError(true);
    setUser(null);
    toast.error(error.response.data.message);
  }
};

export default function SignUp() {
  let navigate = useNavigate();
  const location = useLocation();
  const [error, isError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useAtom(userAtom);

  console.log(location, "location signin");
  let from = location.state?.from?.pathname || "/";
  return (
    <main className="w-full h-full grid grid-cols-4 items-center">
      <div className="col-span-2 flex justify-center items-center pr-14 pt-10">
        <div className="w-4/10 h-1/2 flex items-center justify-center">
          <img src="./logo/main-hero.png" alt="logo"></img>
        </div>
        {/* <Lottie options={lottieSatellite} height={400} width={400} /> */}
      </div>
      <div className="col-span-2 grid grid-rows-12 h-full items-center pr-48">
        <div className="flex flex-col row-span-12 gap-7 w-full items-start justify-center h-full">
          <div className="flex flex-col gap-3">
            <div>
              <h3 className="text-white text-4xl">Sign up</h3>
            </div>
            <div className="text-white">
              Already have an account?
              <span
                className="ml-2 text-my_purple cursor-pointer"
                onClick={() => navigate("/sign-in")}
              >
                Sign in
              </span>
            </div>
          </div>
          <Formik
            initialValues={{
              email: "",
              password: "",
              firstName: "",
              lastName: "",
              confirmPassword: "",
              organisationName: "",
              industryType: "",
              country: "",
              phoneNumber: "",
              jobDescription: "",
              termsAndCondition: false,
            }}
            validationSchema={SignupSchema}
            onSubmit={(values) => {
              // same shape as initial values
              setIsLoading(true);
              onSubmit(
                values,
                isError,
                navigate,
                setIsLoading,

                setUser,
                from
              );
            }}
          >
            {({ errors, touched }) => (
              <Form>
                <div className="flex flex-col gap-5">
                  <div className="grid grid-cols-2 w-full gap-2">
                    <div className="col-span-1 mb-6 md:mb-0 w-full">
                      <label
                        className="block uppercase tracking-wide text-white text-base font-bold mb-2 "
                        htmlFor="firstName"
                      >
                        First Name
                      </label>

                      <Field
                        name="firstName"
                        className={`appearance-none block bg-input_bg text-white rounded py-3 px-4 mb-3 leading-tight w-full focus:bg-background ${
                          (errors.firstName && touched.firstName) || error
                            ? "border border-red-500 focus:outline-none"
                            : "focus:outline-blue-500"
                        }`}
                        id="firstName"
                        type="firstName"
                        autoComplete="off"
                      />
                      <div className="text-red-500 text-xs italic">
                        {errors.firstName && touched.firstName ? (
                          <div className="text-red-500">{errors.firstName}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-span-1 mb-6 md:mb-0 w-full">
                      <label
                        className="block uppercase tracking-wide text-white text-base font-bold mb-2 "
                        htmlFor="lastName"
                      >
                        Last name
                      </label>

                      <Field
                        name="lastName"
                        className={`appearance-none block bg-input_bg text-white w-full rounded py-3 px-4 mb-3 leading-tight focus:bg-background ${
                          (errors.lastName && touched.lastName) || error
                            ? "border border-red-500 focus:outline-none"
                            : "focus:outline-blue-500"
                        }`}
                        id="lastName"
                        type="lastName"
                        autoComplete="off"
                      />
                      <div className="text-red-500 text-xs italic">
                        {errors.lastName && touched.lastName ? (
                          <div className="text-red-500">{errors.lastName}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 w-full gap-2">
                    <div className="col-span-1 mb-6 md:mb-0">
                      <label
                        className="block uppercase tracking-wide text-white text-base font-bold mb-2 "
                        htmlFor="email"
                      >
                        Email
                      </label>

                      <Field
                        name="email"
                        className={`appearance-none block w-full bg-input_bg text-white rounded py-3 px-4 mb-3 leading-tight focus:bg-background ${
                          (errors.email && touched.email) || error
                            ? "border border-red-500 focus:outline-none"
                            : "focus:outline-blue-500"
                        }`}
                        id="email"
                        type="email"
                        autoComplete="off"
                      />
                      <div className="text-red-500 text-xs italic">
                        {errors.email && touched.email ? (
                          <div className="text-red-500">{errors.email}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-span-1 mb-6 md:mb-0">
                      <label
                        className="block uppercase tracking-wide text-white text-base font-bold mb-2 "
                        htmlFor="phoneNumber"
                      >
                        Phone number
                        <span className="text-gray-500 pl-2 text-sm">
                          (optional)
                        </span>
                      </label>

                      <Field
                        name="phoneNumber"
                        className={`appearance-none block w-full bg-input_bg text-white rounded py-3 px-4 mb-3 leading-tight focus:bg-background ${
                          (errors.phoneNumber && touched.phoneNumber) || error
                            ? "border border-red-500 focus:outline-none"
                            : "focus:outline-blue-500"
                        }`}
                        id="phoneNumber"
                        type="phoneNumber"
                        autoComplete="off"
                      />
                      <div className="text-red-500 text-xs italic">
                        {errors.phoneNumber && touched.phoneNumber ? (
                          <div className="text-red-500">
                            {errors.phoneNumber}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 w-full gap-2">
                    <div className="col-span-1 mb-6 md:mb-0">
                      <label
                        className="block uppercase tracking-wide text-white text-base font-bold mb-2 "
                        htmlFor="password"
                      >
                        Password
                      </label>

                      <Field
                        name="password"
                        className={`appearance-none block w-full bg-input_bg text-white rounded py-3 px-4 mb-3 leading-tight focus:bg-background ${
                          (errors.password && touched.password) || error
                            ? "border border-red-500 focus:outline-none"
                            : "focus:outline-blue-500"
                        }`}
                        id="password"
                        type="password"
                        autoComplete="off"
                      />
                      <div className="text-red-500 text-xs italic">
                        {errors.password && touched.password ? (
                          <div className="text-red-500">{errors.password}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-span-1 mb-6 md:mb-0">
                      <label
                        className="block uppercase tracking-wide text-white text-base font-bold mb-2 "
                        htmlFor="confirmPassword"
                      >
                        Confirm Password
                      </label>

                      <Field
                        name="confirmPassword"
                        className={`appearance-none block w-full bg-input_bg text-white rounded py-3 px-4 mb-3 leading-tight focus:bg-background ${
                          (errors.confirmPassword && touched.confirmPassword) ||
                          error
                            ? "border border-red-500 focus:outline-none"
                            : "focus:outline-blue-500"
                        }`}
                        id="confirmPassword"
                        type="password"
                        autoComplete="off"
                      />
                      <div className="text-red-500 text-xs italic">
                        {errors.confirmPassword && touched.confirmPassword ? (
                          <div className="text-red-500">
                            {errors.confirmPassword}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 w-full gap-2">
                    <div className="col-span-1 mb-6 md:mb-0">
                      <label
                        className="block uppercase tracking-wide text-white text-base font-bold mb-2 "
                        htmlFor="organisationName"
                      >
                        Organisation Name
                      </label>

                      <Field
                        name="organisationName"
                        className={`appearance-none block w-full bg-input_bg text-white rounded py-3 px-4 mb-3 leading-tight focus:bg-background ${
                          (errors.organisationName &&
                            touched.organisationName) ||
                          error
                            ? "border border-red-500 focus:outline-none"
                            : "focus:outline-blue-500"
                        }`}
                        id="organisationName"
                        type="organisationName"
                        autoComplete="off"
                      />
                      <div className="text-red-500 text-xs italic">
                        {errors.organisationName && touched.organisationName ? (
                          <div className="text-red-500">
                            {errors.organisationName}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-span-1 mb-6 md:mb-0">
                      <label
                        className="block uppercase tracking-wide text-white text-base font-bold mb-2 "
                        htmlFor="jobDescription"
                      >
                        Job Description
                      </label>

                      <Field
                        name="jobDescription"
                        className={`appearance-none block w-full bg-input_bg text-white rounded py-3 px-4 mb-3 leading-tight focus:bg-background ${
                          (errors.jobDescription && touched.jobDescription) ||
                          error
                            ? "border border-red-500 focus:outline-none"
                            : "focus:outline-blue-500"
                        }`}
                        id="jobDescription"
                        type="jobDescription"
                        autoComplete="off"
                      />
                      <div className="text-red-500 text-xs italic">
                        {errors.jobDescription && touched.jobDescription ? (
                          <div className="text-red-500">
                            {errors.jobDescription}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 w-full gap-2">
                    <div className="col-span-1 mb-6 md:mb-0">
                      <label
                        className="block uppercase tracking-wide text-white text-base font-bold mb-2 "
                        htmlFor="industryType"
                      >
                        Industry Type
                      </label>

                      <Field
                        name="industryType"
                        className={`bg-input_bg text-white w-full rounded py-3 px-4 mb-3 leading-tight ${
                          (errors.industryType && touched.industryType) || error
                            ? "border border-red-500 focus:outline-none"
                            : "focus:outline-blue-500"
                        }`}
                        id="industryType"
                        type="industryType"
                        as="select"
                      >
                        {industryList.map((item, index) => (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        ))}
                      </Field>
                      <div className="text-red-500 text-xs italic">
                        {errors.industryType && touched.industryType ? (
                          <div className="text-red-500">
                            {errors.industryType}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-span-1 mb-6 md:mb-0">
                      <label
                        className="block uppercase tracking-wide text-white text-base font-bold mb-2 "
                        htmlFor="country"
                      >
                        Country
                      </label>

                      <Field
                        name="country"
                        className={` bg-input_bg text-white rounded py-3 px-4 mb-3 leading-tight w-full ${
                          (errors.country && touched.country) || error
                            ? "border border-red-500 focus:outline-none"
                            : "focus:outline-blue-500"
                        }`}
                        id="country"
                        type="country"
                        as="select"
                      >
                        {countryList.map((country, index) => (
                          <option key={index} value={country}>
                            {country}
                          </option>
                        ))}
                      </Field>
                      <div className="text-red-500 text-xs italic">
                        {errors.country && touched.country ? (
                          <div className="text-red-500">{errors.country}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="w-full">
                    <div className="flex flex-col">
                      <label
                        className="text-white flex gap-4 items-center justify-start"
                        htmlFor="termsAndCondition"
                      >
                        <Field type="checkbox" name="termsAndCondition" />
                        <span>
                          I agree to the{" "}
                          <span
                            className="hover:text-blue-400 cursor-pointer"
                            onClick={() =>
                              window
                                .open(
                                  "https://terms-and-condition-airborne.s3.ap-south-1.amazonaws.com/Terms+of+Use_ver+19022022.pdf",
                                  "_blank"
                                )
                                .focus()
                            }
                          >
                            Terms and Condition
                          </span>
                        </span>
                      </label>
                      <div className="text-red-500 text-xs italic">
                        {errors.termsAndCondition &&
                        touched.termsAndCondition ? (
                          <div className="text-red-500">
                            {errors.termsAndCondition}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className={`${isLoading ? "flex w-full" : "w-full"}`}>
                    <button
                      className={` text-center  text-black bg-white rounded py-3 w-full ${
                        isLoading ? "justify-start" : ""
                      }`}
                      type="submit"
                    >
                      Sign up
                    </button>

                    <FaSpinner
                      className={`text-2xl animate-spin items-center relative right-10 top-3 text-black ${
                        isLoading ? "justify-end" : "hidden"
                      }`}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </main>
  );
}
