import * as dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import * as Yup from "yup";
import { isBandsHiddenAtom } from "../../atoms/index";
import { nameSidebarAtom } from "../../atoms/index";
import { showResultsAtom } from "../../atoms/index";
import { areaSectedAtom } from "../../atoms/index";
import { bandSidebarAtom } from "../../atoms/index";
import { orderDetailsAtom } from "../../atoms/index";
import { showToolBarAtom } from "../../atoms/index";
import { areaAtom } from "../../atoms/index";
import { CheckIcon } from "@radix-ui/react-icons";

import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { BiHide } from "react-icons/bi";
import toast from "react-hot-toast";
import axios from "axios";
import * as Checkbox from "@radix-ui/react-checkbox";
import { Formik, Field, Form } from "formik";

dayjs().format();

const BandsValidation = Yup.object().shape({
  checked: Yup.array().min(1, "Select atleast one band from each"),
  sarChecked: Yup.array().min(1, "Select atleast one band from each"),
});

const searchAlgo = async (
  values,
  setDetails,
  details,
  setSidebar,
  setShowResult,
  setShow,
  setArea,
  area,
  setCurrentSlide
) => {
  const toastId = toast.loading("Loading...");
  setSidebar(false);
  setShowResult(false);
  setShow(false);

  const currentArea = area;

  console.log(details, "details");
  console.log(values, "values");
  const sendData = { ...details };
  delete sendData.pipeline_name;
  console.log(sendData, "sendData");
  setDetails({
    ...details,
    optical_bands: values.checked,
    sar_bands: values.sarChecked,
  });
  setCurrentSlide("item-2");
  // //axios post request
  try {
    const req = await axios({
      method: "post",
      url: `${process.env.REACT_APP_GENERAL_SERVER}/api/v1/fetch_tiles`,
      data: { ...sendData },
      headers: { "Content-Type": "application/json", withCredentials: true },
      withCredentials: true,
    });

    const { data } = req.data;
    console.log(data, "Data");
    setDetails({
      ...details,
      ...data,
      optical_bands: values.checked,
      sar_bands: values.sarChecked,
    });
    console.log(details, "details");
    // setIsLoading(false);
    toast.success("Tiles fetched successfully", {
      autoClose: 5000,
      closeOnClick: true,
      pauseOnHover: true,
      id: toastId,
    });
    setShowResult(true);
    setShow(false);
  } catch (error) {
    console.log("error");
    console.log(error.response);
    if (error.response.data.message === "certificate has expired") {
      toast.error(
        "Sentinel servers are facing some issue, Please try again later.",
        {
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
          id: toastId,
        }
      );
    } else if (error.response.data.status === 504) {
      toast.error("Sentinel is facing server issues, please try again later.", {
        autoClose: 9000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        id: toastId,
      });
    } else if (error.response.status === 606) {
      toast.error("Can't fetch SAR Data, try incresing the date range!", {
        autoClose: 9000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        id: toastId,
      });
    } else {
      toast.error(
        "Please try changing the date range or cloud cover percentage",
        {
          autoClose: 9000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          id: toastId,
        }
      );
    }
    setSidebar(true);
    setShow(true);
    setArea(currentArea);
  }
};

const list = [
  { name: "Band 1 - Coastal aerosol", value: "band01" },
  { name: "Band 2 - Blue", value: "band02" },
  { name: "Band 3 - Green", value: "band03" },
  { name: "Band 4 - Red", value: "band04" },
  { name: "Band 5 - Vegetation Red Edge", value: "band05" },
  { name: "Band 6 - Vegetation Red Edge", value: "band06" },
  { name: "Band 7 - Vegetation Red Edge", value: "band07" },
  { name: "Band 8 - NIR", value: "band08" },
  { name: "Band 8A - Vegetation Red Edge", value: "band8A" },
  { name: "Band 9 - Water Vapour", value: "band09" },
  { name: "Band 10 - SWIR - Cirrus", value: "band10" },
  { name: "Band 11 - SWIR", value: "band11" },
  { name: "Band 12 - SWIR", value: "band12" },
];

const sarList = [
  { name: "VV", value: "vv" },
  { name: "VH", value: "vh" },
];

const checkBoxes = (item, index, value) => {
  return (
    <div className="flex items-center justify-center gap-2 " key={index}>
      <label className="text-white w-full flex items-center gap-4">
        <Field
          type="checkbox"
          name={value}
          value={item.value}
          className="bg-white w-5 h-5 rounded hover:border-violet-600 hover:border-2 focus:shadow-order_background"
        />
        <span className="text-0.8">{item.name}</span>
      </label>
    </div>
  );
};

const goBack = (setCurrentSlide) => {
  setCurrentSlide("item-2");
};

const BandsSidebar = ({ setCurrentSlide }) => {
  const [selected, setSelected] = useAtom(areaSectedAtom);
  const [details, setDetails] = useAtom(orderDetailsAtom);
  const [sidebar, setSidebar] = useAtom(nameSidebarAtom);
  const [showResult, setShowResult] = useAtom(showResultsAtom);
  const [show, setShow] = useAtom(showToolBarAtom);
  const [area, setArea] = useAtom(areaAtom);

  console.log(details, "details");

  return (
    <div>
      <Formik
        validationSchema={BandsValidation}
        initialValues={{
          checked: details.optical_bands || [],
          sarChecked: details.sar_bands || [],
        }}
        onSubmit={(values) =>
          searchAlgo(
            values,
            setDetails,
            details,
            setSidebar,
            setShowResult,
            setShow,
            setArea,
            area,
            setCurrentSlide
          )
        }
      >
        {({ values, errors, touched }) => (
          <Form>
            <div className="flex justify-items-start w-full h-full relative">
              <div
                className="absolute -top-1 left-1"
                onClick={() => goBack(setCurrentSlide)}
              >
                <MdOutlineKeyboardBackspace className="text-4xl cursor-pointer text-white" />
              </div>
              <div className="font-pipeline_name text-xl text-pipeline_name uppercase pb-3 text-center w-full">
                <h2>Select Bands</h2>
              </div>
            </div>
            <div className="flex flex-col gap-2 w-full">
              <div className="h-full py-2 flex flex-col gap-5 items-center w-full">
                <h2 className=" text-white uppercase">Optical bands</h2>
                <div className="flex flex-col gap-5 h-48 overflow-y-scroll mx-auto py-5">
                  {list.map((item, index) => {
                    return checkBoxes(item, index, "checked");
                  })}
                </div>
                <div className="w-full flex flex-col gap-5 items-center justify-center text-center">
                  <h2 className=" text-white uppercase">SAR bands</h2>
                  <div className="flex gap-10">
                    {sarList.map((item, index) => {
                      return checkBoxes(item, index, "sarChecked");
                    })}
                  </div>
                </div>
                <div className="text-red-500 text-xs italic">
                  {(errors.checked && touched.checked) ||
                  (errors.sarChecked && touched.sarChecked) ? (
                    <div>{errors.checked || errors.sarChecked}</div>
                  ) : null}
                </div>
              </div>
              <div className="w-full flex justify-center row-start-10">
                <button
                  className="sm:py-2 sm:px-4 px-3 w-1/3 py-2 rounded-sm order-2 md:order-3 border ml-5 text-black border-white  bg-white  shadow-2xl dark:shadow-none transition duration-500 ease-in-out hover:-translate-y-1 hover:scale-105 active:translate-y-3"
                  type="submit"
                >
                  Search
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default BandsSidebar;
